import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Alert from "../../components/_ui/Alert.jsx";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Disclaimer"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Disclaimer`}</h1>
    <Alert type="warning" mdxType="Alert">
  <span className="text-label">Important</span>
  <p className="text-paragraph">
    Perfect Pergolas Limited has produced this basic information guide on how to
    assemble and construct your pergola. This information is provided for use on
    the strict understanding that Perfect Pergolas Limited is not liable for any
    loss or damage which is suffered or incurred (including but not limited to
    indirect or consequential loss) for any personal injury or damage to
    property suffered or sustained as a result of using the information
    contained in these guidelines.
  </p>
    </Alert>
    <p>{`Perfect Pergolas Limited recommends you to contact and seek the full advices of a qualified professional tradesperson, such as an electrician or plumber, where expert services are required and to independently assess any health and safety precautions that will need to be followed or adhered to prior to using the information provided by this guide.`}</p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      